import React from "react"
import "./hinnat.css"

export default function Example() {
  return (
    <div>
      <section aria-labelledby="faq-heading" className="bg-white">
        <div className="bg-white">
          <br />
          <div className="max-w-2xl lg:mx-auto mx-auto">
            <h2 className="text-4xl font-extrabold text-gray-900">
              Hinnat 2025
            </h2>
            <br />
            <br />
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
              Päärakennus juhlatilana
            </h2>
            <br />
            <br />
          </div>
        </div>

        <div className="hintaContainer max-w-2xl text-left mx-auto text-gray-700 text-3xl">
          <div>
            <p>Henkilömäärä</p>
          </div>
          <div>
            <p>20-40</p>
          </div>
          <div>
            <p>
              790,65
              <span className="text-sm"> alv 25.5%</span>
            </p>
          </div>
          <div>
            <p>Henkilömäärä</p>
          </div>
          <div>
            <p>40-60</p>
          </div>
          <div>
            <p>
              978,90
              <span className="text-sm"> alv 25.5%</span>
            </p>
          </div>
        </div>

        <div className="bg-white">
          <br />
          <div className="max-w-2xl lg:mx-auto mx-auto">
            <br />
            <h2 className="text-3xl font-extrabold text-gray-900">
              Puimala juhlatilana
            </h2>
            <br />
            <br />
          </div>
        </div>

        <div className="hintaContainer max-w-2xl text-left mx-auto text-gray-700 text-3xl">
          <div>
            <p>Henkilömäärä</p>
          </div>
          <div>
            <p>60-80</p>
          </div>
          <div>
            <p>
              1204,80
              <span className="text-sm"> alv 25.5%</span>
            </p>
          </div>
          <span className="text-gray-700 text-sm">
            Hinta sisältää päärakennuksen keittiön käytön
          </span>
        </div>

        <div className="max-w-2xl lg:mx-auto lg:text-center mx-auto">
          <p className="mt-10 text-gray-700 text-xl">
            Hinnat ovat esimerkkihintoja.
          </p>
        </div>

        <div className="bg-white"></div>
        <div className="max-w-2xl lg:mx-auto mx-auto">
          <p className="mt-10 text-gray-700 text-xl">
            Hinta räätälöidään tilaisuuden mukaisesti riippuen siitä, onko
            Puimala juhlatilana vai juhlien jatkopaikkana.
          </p>
          <br />
          <br />
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="max-w-2xl lg:mx-auto lg:text-center mx-auto">
          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
            Tervetuloa Sipoon Jokirannan kartanoon - juhlatilat elämäsi kaikkiin
            juhliin
          </h2>
        </div>
      </section>
    </div>
  )
}
