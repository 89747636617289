import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Features from "../components/features"
import Cta from "../components/cta"
import Gallery from "../components/gallery"
import GoogleMap from "../components/googleMap"
import InfoPaarakennus from "../components/infoPaarakennus"
import InfoPuimala from "../components/infoPuimala"
import InfoYleiset from "../components/infoYleiset"
import Hinnat from "../components/hinnat"

const IndexPage = () => (
  <Layout>
    <SEO title="Peltotie 34" />
    <Features />
    <Gallery />
    <GoogleMap />
    <InfoPaarakennus />
    <InfoPuimala />
    <InfoYleiset />
    <Hinnat />
    <Cta />
  </Layout>
)

export default IndexPage
