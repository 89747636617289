import React from "react"
import './googleMap.css'

export default function Example() {
    return (
      <div className="grid place-content-center google-map-responsive">
        <div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7898.516048390563!2d25.1194975!3d60.335721!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa1b1e2cbec8119a4!2sPeltotie34%20-%20Jokirannan%20Kartano!5e0!3m2!1sfi!2sfi!4v1644060391497!5m2!1sfi!2sfi" width="600" height="450"  allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
    )
  }