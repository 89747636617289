import React from "react"

const faqs = [
  {
    id: 1,
    question: "Varustelu",
    answer:
      "Päärakennuksessa on pöydät ja tuolit 60 hengelle. Astiasto  70 hengelle, sisältäen kahvikupit ja lautaset, jälkiruokalautaset, vesi-, viini- ja kuohuviinilasit, atrimet. Vesikannuja ja salaattiottimia, kattiloita etc. Ruokailupöytiin on valkoiset pöytäliinat, buffetpöytiin ei. Asiakas voi myös tuoda omat pöytäliinat. Jätesäkit, WC-paperit, saippuat ja tiskiaineet kartanon puolesta. Samoin siivousvälineet.",
  },
  {
    id: 2,
    question: "Keittiö",
    answer:
      "Keittiössä on jääkaappi-kylmäkaappi, jääkaappi-pakastin, induktiohella, astianpesukone, mikroaaltouuni, kahvinkeittimet, pumpputermoskannut. Kartanon aulassa on viinikaappi. Kartanon keittiöön on oma sisäänkäynti ja auton saa ajettua aivan oven eteen.?",
  },
]

export default function Example() {
  return (
    <section aria-labelledby="faq-heading" className="bg-white">
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="max-w-2xl lg:mx-auto lg:text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Päärakennuksen vuokraus
            </h2>
            <p className="mt-4 text-gray-700 text-xl">
              Vuokra-aika noin klo 9.00-23.00, lopputoimet voi tehdä klo 01.00
              mennessä tai sopimuksen mukaan seuraavana aamupäivänä. Asiakas huolehtii kaikkien tilojen siivoukset,
              siivousvälineet tulevat kartanolta.
              <br />
              <br />
              Vuokra-ajat sovitaan aina tilaisuuden luonteen mukaisesti. Tiloja
              vuokrataan myös muutamien tuntien tai iltapäivän ajaksi. Usein valmistelut voi
              veloituksetta tehdä juhlia edeltävänä iltapäivänä.
            </p>
          </div>
          <div className="mt-8">
            <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
              {faqs.map(faq => (
                <div key={faq.id}>
                  <dt className="font-semibold text-3xl text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-3 text-xl text-gray-700">{faq.answer}</dd>
                  <br />
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </section>
  )
}
